import {
  AspectRatio,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import axios from 'axios';
import { CompositeDecorator, ContentState, Editor, EditorState, convertFromHTML, convertFromRaw } from 'draft-js';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import ImageIcon from '../../icons/Image';
import AddressInput from '../form/AddressInput';

const defaultBodyText = `Type into the BODY TEXT field on the left for your text to show up here. Customize your copy with <b>bold</b>, <i>italicized</i>, or <u>underlined</u> text. <b>Tip: Leaving a field blank in Loop will exclude it from your bulletin.</b>`;
const blocksFromHTML = convertFromHTML(defaultBodyText);
const defaultBodyState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

function ConnectCard(props) {
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  const Link = (props) => {
    let { url } = props.contentState.getEntity(props.entityKey).getData();
    url = url.replace(/^(?:https?:\/\/)?/i, '');

    return (
      <Box
        as="a"
        href={`https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        color="theme.primary"
        style={{ textDecoration: 'underline' }}
      >
        {props.children}
      </Box>
    );
  };

  const strategyDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const { register, handleSubmit, control, formState } = useForm();
  const env = runtimeEnv();
  const toast = useToast();

  const instance = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
    },
  });

  const onSubmit = (values) => {
    if (values.hasOwnProperty('phone')) {
      values['phone'] = values['phone'].replace(/\D+/g, '');
    }

    const data = {
      type: 'responses',
      attributes: {
        form: 'connect_card',
        content: JSON.stringify(values),
      },
    };

    instance
      .post(`/api/v1/responses`, { data })
      .then((res) => {
        props.handleClose();
        toast({
          description: 'Thank you for your submission.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          description: 'There was an error submitting. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <VStack spacing={5} align="stretch">
      <Heading color="theme.primary" pl="5" pr="5" align="center" size="md">
        {props.details?.title ? props.details.title : !props.errors.hasOwnProperty('title') && 'Connect Card'}
      </Heading>
      {(!props.live || props.details?.image) && (
        <AspectRatio ratio={16 / 9}>
          {props.details?.image ? (
            <Box>
              <Image src={props.details.image} objectFit="cover" width="inherit" />
            </Box>
          ) : (
            <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
              <ImageIcon />
            </Box>
          )}
        </AspectRatio>
      )}
      {(!props.live || props.details?.body_text) && (
        <Box pl="5" pr="5" color="theme.body" opacity={props.details?.body_text ? 1 : '0.4'}>
          <Editor
            readOnly={true}
            editorState={
              props.details?.body_text
                ? EditorState.createWithContent(convertFromRaw(props.details.body_text), strategyDecorator)
                : EditorState.createWithContent(defaultBodyState, strategyDecorator)
            }
          />
        </Box>
      )}
      <Box pl="5" pr="5" align="center">
        <form onSubmit={handleSubmit(onSubmit)}>
          {(props.details?.fields?.name || !props.details.hasOwnProperty('fields')) && (
            <FormControl mt="5" mb="5">
              <FormLabel>Name</FormLabel>
              <Input
                ref={register}
                type="text"
                name="name"
                autocomplete="name"
                focusBorderColor="theme.primary"
                colorScheme="theme"
              />
            </FormControl>
          )}
          {(props.details?.fields?.email || !props.details.hasOwnProperty('fields')) && (
            <FormControl mt="5" mb="5">
              <FormLabel>Email</FormLabel>
              <Input
                ref={register}
                type="email"
                name="email"
                autocomplete="home email"
                focusBorderColor="theme.primary"
                colorScheme="theme"
              />
            </FormControl>
          )}
          {props.details?.fields?.phone && (
            <FormControl mt="5" mb="5">
              <FormLabel>Phone Number</FormLabel>
              <Controller
                name="phone"
                defaultValue=""
                control={control}
                render={({ onChange }) => (
                  <Input
                    focusBorderColor="theme.primary"
                    as={NumberFormat}
                    onChange={onChange}
                    format="(###) ###-#########"
                    type="tel"
                    colorScheme="theme"
                    autocomplete="mobile tel"
                  />
                )}
              />
            </FormControl>
          )}
          {props.details?.fields?.address && (
            <FormControl mt="5" mb="5">
              <FormLabel>Address</FormLabel>
              <Controller
                name="address"
                defaultValue=""
                control={control}
                render={({ onChange }) => <AddressInput colorScheme="theme" onChange={onChange} />}
              />
            </FormControl>
          )}
          {props.details?.responses &&
            props.details.responses.map((response, index) => {
              let placeholder = {};

              switch (index) {
                case 0:
                  placeholder = {
                    question: 'I made a decision today',
                    options: ['To follow Jesus', 'To rededicate my life to Jesus'],
                  };
                  break;
                case 1:
                  placeholder = {
                    question: 'How did you hear about us?',
                    options: ['Friend/Family', 'Church website'],
                  };
                  break;
                default:
                  placeholder = {
                    question: 'Response question',
                    options: ['First option', 'Second option'],
                  };
              }
              return (
                (!props.live || response.question) && (
                  <Box align="left" mt="5" mb="5" key={index}>
                    <Text color="theme.body" opacity={response.question ? 1 : '0.4'}>
                      {response.question ? response.question : placeholder.question}
                      <Input
                        type="hidden"
                        name={`responses[${index}].question`}
                        ref={register}
                        defaultValue={response.question}
                        focusBorderColor="theme.primary"
                      />
                    </Text>
                    <VStack mt="2" align="left">
                      {response?.options &&
                        response.options.map(
                          (option, optionIndex) =>
                            (!props.live || option) && (
                              <Checkbox
                                name={`responses[${index}].options[${optionIndex}]`}
                                ref={register}
                                key={optionIndex}
                                value={option}
                                colorScheme="theme"
                                opacity={option ? 1 : '0.4'}
                              >
                                {option ? option : placeholder.options[optionIndex]}
                              </Checkbox>
                            )
                        )}
                    </VStack>
                  </Box>
                )
              );
            })}
          <Button
            color="theme.background"
            bg="theme.primary"
            colorScheme="theme"
            mt="5"
            mb="5"
            isDisabled={!props.live || (props.live && !formState.isDirty)}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Box>
    </VStack>
  );
}

export default ConnectCard;
