import { AspectRatio, Box, Heading, Image, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useContext } from 'react';

import { AuthenticationContext } from '../../contexts/authentication-context';
import ImageIcon from '../../icons/Image';

function Header(props) {
  const { state } = useContext(AuthenticationContext);

  return (
    <VStack pt="10" spacing={5} align="stretch" onClick={props.onClick} className={!props.live && 'card__preview'}>
      <Box pb="2">
        <Heading color="theme.primary" pl="5" pr="5" align="center" size="lg">
          {props.details?.title
            ? props.details.title
            : !props.errors.hasOwnProperty('title') && `${state.organization.name} Bulletin`}
        </Heading>
        <Heading color="theme.body" pt="2" pl="5" pr="5" align="center" size="sm">
          {props.details?.date
            ? dayjs(props.details.date).format('MMMM D, YYYY')
            : dayjs(new Date()).format('MMMM D, YYYY')}
        </Heading>
      </Box>
      {(!props.live || props.details?.image) && (
        <AspectRatio ratio={16 / 9}>
          {props.details?.image ? (
            <Box>
              <Image src={props.details.image} objectFit="cover" width="inherit" />
            </Box>
          ) : (
            <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
              <ImageIcon />
            </Box>
          )}
        </AspectRatio>
      )}
    </VStack>
  );
}

export default Header;
