import * as React from 'react';

function PowerIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M127.992 48.003v76M176.002 54.233a88 88 0 11-96.004 0"
      />
    </svg>
  );
}

export default PowerIcon;
