import * as React from 'react';

function BrandWordMarkIcon(props) {
  return (
    <svg width="5em" height="3em" viewBox="0 0 597 319" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.872 127.132c-7.469-.287-15.461 2.686-17.397 3.682 15.077 24.889 11.743 60.116-11.493 78.961-25.382 20.586-62.646 16.697-83.231-8.685-20.585-25.381-16.697-62.645 8.685-83.23 22.639-18.36 56.03-15.731 77.308 2.734.228-.382.496-.811.792-1.287 1.9-3.043 4.978-7.975 6.174-14.605 2.214-12.265-7.554-23.606-19.333-27.676-27.206-9.401-57.537-4.796-82.773 15.671-12.661 10.268-21.95 22.887-27.688 36.601a82.508 82.508 0 00-5.507-11.882c-7.776-13.608-18.36-24.192-31.752-31.752C174.265 77.888 158.821 74 141.325 74c-17.28 0-32.616 3.888-46.008 11.664-13.392 7.56-23.868 18.144-31.428 31.752-7.777 13.392-11.665 28.944-11.665 46.656 0 17.496 3.78 33.048 11.34 46.656 7.56 13.392 18.036 23.976 31.429 31.752 13.392 7.56 28.836 11.34 46.332 11.34 17.496 0 32.94-3.78 46.332-11.34 13.392-7.776 23.976-18.36 31.752-31.752a85.892 85.892 0 005.796-12.808c3.287 7.668 7.724 15.068 13.34 21.992 32.197 39.699 87.426 44.396 127.126 12.198 25.181-20.422 35.858-49.202 32.26-77.802-1.648-13.099-10.866-26.669-24.059-27.176zm-262.355 89.752c8.64 5.184 18.576 7.776 29.808 7.776s21.168-2.592 29.808-7.776c8.64-5.184 15.444-12.312 20.412-21.384 4.968-9.072 7.452-19.548 7.452-31.428 0-11.88-2.484-22.356-7.452-31.428-4.968-9.288-11.772-16.524-20.412-21.708-8.64-5.184-18.576-7.776-29.808-7.776s-21.168 2.592-29.808 7.776c-8.64 5.184-15.337 12.42-20.088 21.708-4.969 9.072-7.453 19.548-7.453 31.428 0 11.88 2.484 22.356 7.453 31.428 4.751 9.072 11.448 16.2 20.088 21.384z"
        fill="currentColor"
      />
      <path
        d="M447.806 318.612c-8.856.259-19.029.633-25.294-5.632-3.024-3.024-4.536-6.912-4.536-11.664V153.572c.216-17.064 4.212-32.4 11.988-46.008 7.776-13.608 18.36-24.3 31.752-32.076 13.392-7.992 28.512-11.988 45.36-11.988 17.064 0 32.292 3.996 45.684 11.988 13.392 7.776 23.976 18.468 31.752 32.076 7.992 13.608 11.988 28.944 11.988 46.008 0 17.064-3.672 32.4-11.016 46.008-7.128 13.392-16.956 24.084-29.484 32.076-12.528 7.776-26.676 11.664-42.444 11.664-12.744 0-24.516-2.592-35.316-7.776-10.584-5.4-19.656-12.636-27.216-21.708V318.5s-1.301.056-3.218.112zm59.27-104.452c11.016 0 20.844-2.592 29.484-7.776 8.64-5.4 15.444-12.636 20.412-21.708 5.184-9.288 7.776-19.656 7.776-31.104 0-11.664-2.592-22.032-7.776-31.104-4.968-9.288-11.772-16.524-20.412-21.708-8.64-5.4-18.468-8.1-29.484-8.1-10.8 0-20.628 2.7-29.484 8.1-8.64 5.184-15.444 12.42-20.412 21.708-4.968 9.072-7.452 19.44-7.452 31.104 0 11.448 2.484 21.816 7.452 31.104 4.968 9.072 11.772 16.308 20.412 21.708 8.856 5.184 18.684 7.776 29.484 7.776z"
        fill="currentColor"
      />
      <path
        d="M0 16.191c0-4.75 1.493-8.635 4.48-11.657C7.467 1.51 11.307 0 16 0s8.533 1.511 11.52 4.534C30.507 7.556 32 11.442 32 16.19v5.681l1 126.34v104.566s-1.299.056-3.213.112l-.303.009c-8.772.257-18.771.55-24.955-5.636C1.51 244.242 0 240.358 0 235.61V16.191z"
        fill="currentColor"
      />
    </svg>
  );
}

export default BrandWordMarkIcon;
