import { AspectRatio, Box, Heading, VStack } from '@chakra-ui/react';
import { CompositeDecorator, ContentState, Editor, EditorState, convertFromHTML, convertFromRaw } from 'draft-js';
import React, { useEffect, useState } from 'react';

import CodeIcon from '../../icons/Code';
import VideoIcon from '../../icons/Video';
import WebsiteIcon from '../../icons/Website';
import FacebookVideo from './video/FacebookVideo';
import TwitchVideo from './video/TwitchVideo';
import VimeoVideo from './video/VimeoVideo';
import YoutubeVideo from './video/YoutubeVideo';

const defaultBodyText = `Type into the BODY TEXT field on the left for your text to show up here. Customize your copy with <b>bold</b>, <i>italicized</i>, or <u>underlined</u> text. <b>Tip: Leaving a field blank in Loop will exclude it from your bulletin.</b>`;
const blocksFromHTML = convertFromHTML(defaultBodyText);
const defaultBodyState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

function Embed(props) {
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  const Link = (props) => {
    let { url } = props.contentState.getEntity(props.entityKey).getData();
    url = url.replace(/^(?:https?:\/\/)?/i, '');

    return (
      <Box
        as="a"
        href={`https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        color="theme.primary"
        style={{ textDecoration: 'underline' }}
      >
        {props.children}
      </Box>
    );
  };

  const strategyDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const [videoPlatform, setVideoPlatform] = useState(<React.Fragment />);
  const createMarkup = () => {
    return { __html: props.details.code };
  };

  useEffect(() => {
    if (props.details.embed_type === 'video' && props.details?.url) {
      let formattedUrl = props.details.url;
      if (!formattedUrl.includes('https://')) {
        formattedUrl = `https://${props.details.url}`;
      }

      const parsedUrl = new URL(formattedUrl);
      if (!parsedUrl) {
        return;
      }

      switch (parsedUrl.hostname.toLowerCase()) {
        case 'facebook.com':
        case 'www.facebook.com':
          setVideoPlatform(<FacebookVideo src={formattedUrl} />);
          break;

        case 'twitch.tv':
        case 'player.twitch.tv':
        case 'www.twitch.tv':
          setVideoPlatform(<TwitchVideo src={formattedUrl} />);
          break;

        case 'vimeo.com':
        case 'player.vimeo.com':
        case 'www.vimeo.com':
          setVideoPlatform(<VimeoVideo src={formattedUrl} />);
          break;

        case 'youtu.be':
        case 'youtube.com':
        case 'www.youtube.com':
          setVideoPlatform(<YoutubeVideo src={formattedUrl} />);
          break;

        default:
          setVideoPlatform(
            <Box align="center">
              <iframe
                title={`${props.details.url}`}
                src={`https://${props.details.url}`}
                style={{ width: '100%', height: 'calc(100vh - 6rem)' }}
              />
            </Box>
          );
      }
    }
  }, [props.details?.url]);

  return (
    <VStack spacing={5} align="stretch">
      <Heading color="theme.primary" pl="5" pr="5" align="center" size="md">
        {props.details?.title ? props.details.title : !props.errors.hasOwnProperty('title') && 'Embed'}
      </Heading>
      {props.details?.embed_type && (
        <React.Fragment>
          {props.details.embed_type === 'code' &&
            (props.details?.code ? (
              <Box align="center">
                <Box dangerouslySetInnerHTML={createMarkup()} />
              </Box>
            ) : (
              !props.live && (
                <AspectRatio ratio={16 / 9}>
                  <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
                    <CodeIcon />
                  </Box>
                </AspectRatio>
              )
            ))}

          {props.details.embed_type === 'url' &&
            (props.details?.url ? (
              <Box align="center">
                <iframe
                  title={`${props.details.url}`}
                  src={`https://${props.details.url}`}
                  style={{ width: '100%', height: 'calc(100vh - 6rem)' }}
                />
              </Box>
            ) : (
              !props.live && (
                <AspectRatio ratio={9 / 16}>
                  <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
                    <WebsiteIcon />
                  </Box>
                </AspectRatio>
              )
            ))}

          {props.details.embed_type === 'video' && (
            <React.Fragment>
              {(!props.live || props.details?.body_text) && (
                <Box pl="5" pr="5" color="theme.body" opacity={props.details?.body_text ? 1 : '0.4'}>
                  <Editor
                    readOnly={true}
                    editorState={
                      props.details?.body_text
                        ? EditorState.createWithContent(convertFromRaw(props.details.body_text), strategyDecorator)
                        : EditorState.createWithContent(defaultBodyState, strategyDecorator)
                    }
                  />
                </Box>
              )}

              {props.details?.url ? (
                <Box align="center">{videoPlatform}</Box>
              ) : (
                !props.live && (
                  <AspectRatio ratio={16 / 9}>
                    <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
                      <VideoIcon />
                    </Box>
                  </AspectRatio>
                )
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </VStack>
  );
}

export default Embed;
