import * as React from 'react';

function AddIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a.864.864 0 00-.865.865v6.27H.865a.864.864 0 100 1.73h6.27v6.27a.864.864 0 101.73 0v-6.27h6.27a.864.864 0 100-1.73h-6.27V.865A.864.864 0 008 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AddIcon;
