import * as React from 'react';

function UserCircleIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <circle cx={128} cy={128} r={96} fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={16} />
      <circle cx={128} cy={120} r={40} fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={16} />
      <path
        d="M63.8 199.374a72.028 72.028 0 01128.4 0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </svg>
  );
}

export default UserCircleIcon;
