import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import React from 'react';
import { ErrorBoundary as LocalErrorBoundary } from 'react-error-boundary';

import ErrorAlert from './ErrorAlert';

const ErrorHandler = (props) => {
  const env = runtimeEnv();

  if (process.env.NODE_ENV !== 'development') {
    Bugsnag.start({
      apiKey: env.REACT_APP_BUGSNAG_API_KEY,
      appVersion: env.REACT_APP_BUGSNAG_APP_VERSION,
      releaseStage: env.REACT_APP_APP_ENV,
      plugins: [new BugsnagPluginReact()],
    });

    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
    return <ErrorBoundary FallbackComponent={ErrorAlert}>{props.children}</ErrorBoundary>;
  }

  return <LocalErrorBoundary FallbackComponent={ErrorAlert}>{props.children}</LocalErrorBoundary>;
};

export default ErrorHandler;
