import * as React from 'react';

function RightArrowIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.23 11.094a.567.567 0 00.84.037.57.57 0 00-.038-.839l-4.09-4.095H15.46a.57.57 0 000-1.14H1.942L6.038.968a.58.58 0 000-.801.566.566 0 00-.801 0L.17 5.233h.001a.558.558 0 000 .8l5.06 5.06z"
        fill="currentColor"
      />
    </svg>
  );
}

export default RightArrowIcon;
