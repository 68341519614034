import * as React from 'react';

function CodeIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.832 8.07a.571.571 0 000-.809L11.676 3.12a.575.575 0 00-.812 0 .571.571 0 000 .808l3.751 3.739-3.75 3.738a.57.57 0 000 .808.575.575 0 00.811 0l4.156-4.142zM.168 7.828a.572.572 0 010-.809l4.156-4.142a.575.575 0 01.811 0 .57.57 0 010 .809l-3.75 3.738 3.75 3.738a.57.57 0 010 .808.575.575 0 01-.811 0L.168 7.828z"
        fill="currentColor"
      />
      <path stroke="currentColor" strokeLinecap="round" d="M7.041 13.066L9.009 2.401" />
    </svg>
  );
}

export default CodeIcon;
