import { AspectRatio, Box, Flex, Heading, Image, VStack } from '@chakra-ui/react';
import { ContentState, Editor, EditorState, convertFromRaw, CompositeDecorator } from 'draft-js';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';

import HandleIcon from '../../icons/Handle';
import ImageIcon from '../../icons/Image';

function Service(props) {
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  const Link = (props) => {
    let { url } = props.contentState.getEntity(props.entityKey).getData();
    url = url.replace(/^(?:https?:\/\/)?/i, '');

    return (
      <Box
        as="a"
        href={`https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        color="theme.primary"
        style={{ textDecoration: 'underline' }}
      >
        {props.children}
      </Box>
    );
  };

  const strategyDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const _dragEl = document.getElementById('preview_draggable');

  const onDragEnd = (result) => {
    return props.handleDrag(result);
  };

  const optionalPortal = (styles, element) => {
    if (styles.position === 'fixed') {
      return createPortal(element, _dragEl);
    }
    return element;
  };

  return (
    <VStack spacing={5} align="stretch">
      <Heading color="theme.primary" pl="5" pr="5" align="center" size="md">
        {props.details?.title ? props.details.title : !props.errors.hasOwnProperty('title') && 'Order of Service'}
      </Heading>
      {(!props.live || props.details?.image) && (
        <AspectRatio ratio={16 / 9}>
          {props.details?.image ? (
            <Box>
              <Image src={props.details.image} objectFit="cover" width="inherit" />
            </Box>
          ) : (
            <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
              <ImageIcon />
            </Box>
          )}
        </AspectRatio>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable key="topics" droppableId="topics">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <Box>
                {props.details?.topics &&
                  props.details.topics.map((topic, index) => {
                    let placeholder = {
                      title: '',
                      text: '',
                    };

                    switch (index) {
                      case 0:
                        placeholder = {
                          title: 'Service Topics 101',
                          text:
                            'Add, edit, and delete your service topics using the panel on the left. Hover over a topic here in the phone, then click and drag to move it up or down in the list.',
                        };
                        break;
                      case 1:
                        placeholder = {
                          title: 'Keep everyone in the Loop',
                          text: 'List your music, scripture, guests, and more!',
                        };
                    }

                    return (
                      <Draggable
                        key={index.toString()}
                        draggableId={index.toString()}
                        index={index}
                        isDragDisabled={props.live}
                      >
                        {(provided, snapshot) =>
                          optionalPortal(
                            provided.draggableProps.style,
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ ...provided.draggableProps.style }}
                              pl={props.live ? '8' : '2'}
                              pr={props.live ? '8' : '8'}
                              pt="3"
                              pb="3"
                              className={!props.live && 'card__preview'}
                            >
                              <Flex align="center">
                                {!props.live && (
                                  <Box
                                    mr="1"
                                    fontSize="xl"
                                    color="theme.primary"
                                    style={{ cursor: 'grab' }}
                                    className="handle"
                                  >
                                    <HandleIcon />
                                  </Box>
                                )}
                                <Box flex="1">
                                  {(!props.live || (props.live && topic?.title)) && (
                                    <Heading size="md" pb="1" color="theme.body" opacity={topic?.title ? 1 : '0.4'}>
                                      {topic?.title ? topic.title : placeholder.title}
                                    </Heading>
                                  )}
                                  {(!props.live || (props.live && topic?.text)) && (
                                    <Box color="theme.body" opacity={topic?.text ? 1 : '0.4'}>
                                      <Editor
                                        readOnly={true}
                                        editorState={
                                          topic?.text
                                            ? EditorState.createWithContent(convertFromRaw(topic.text), strategyDecorator)
                                            : EditorState.createWithContent(
                                                ContentState.createFromText(placeholder.text),
                                                strategyDecorator
                                              )
                                        }
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Flex>
                            </Box>
                          )
                        }
                      </Draggable>
                    );
                  })}
              </Box>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </VStack>
  );
}

export default Service;
