import * as React from 'react';

function TrialEndedIllustration(props) {
  return (
    <svg width="10em" height="4em" viewBox="0 0 421 169" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={421} height={146} rx={30} transform="matrix(-1 0 0 1 421 23)" fill="#F4F8FA" />
      <path
        d="M212.044 160.492c38.131 0 69.043-30.912 69.043-69.044 0-38.131-30.912-69.043-69.043-69.043-38.132 0-69.044 30.912-69.044 69.043 0 38.132 30.912 69.044 69.044 69.044z"
        fill="#6E4FED"
      />
      <path
        d="M212.044 154.776c34.975 0 63.328-28.353 63.328-63.328S247.019 28.12 212.044 28.12s-63.328 28.353-63.328 63.328 28.353 63.328 63.328 63.328z"
        fill="#fff"
      />
      <path
        d="M211.814 100.136a8.459 8.459 0 100-16.918 8.46 8.46 0 000 16.918zM213.643 36.35v-3.2a1.829 1.829 0 00-3.658 0v3.201a1.829 1.829 0 003.658 0zM213.643 150.204v-3.201a1.83 1.83 0 00-3.658 0v3.201a1.83 1.83 0 003.658 0zM153.289 93.506h3.2a1.828 1.828 0 100-3.658h-3.2a1.83 1.83 0 000 3.658z"
        fill="#6E4FED"
      />
      <path d="M272.171 93.506v-3.658h-6.859v3.658h6.859z" fill="#3F3D56" />
      <path
        d="M209.376 95.35l3.126-7.149 39.171 21.694-42.297-14.546zM220.959 5.715h-17.832v20.576h17.832V5.715z"
        fill="#6E4FED"
      />
      <path
        d="M212.043 11.431c11.363 0 20.576-2.559 20.576-5.715 0-3.157-9.213-5.716-20.576-5.716-11.364 0-20.576 2.559-20.576 5.716 0 3.156 9.212 5.715 20.576 5.715z"
        fill="#6E4FED"
      />
      <path
        d="M250.566 103.679l-1.143 1.601s-5.258 6.173-5.487 3.201c-.229-2.973 3.887-5.03 3.887-5.03l2.286-1.372.457 1.6zM283.11 109.079l.957 1.718s3.329 7.394.545 6.329c-2.784-1.065-2.883-5.665-2.883-5.665l-.261-2.653 1.642.271z"
        fill="#BC8B83"
      />
      <path d="M277.961 78.615a6.962 6.962 0 100-13.923 6.962 6.962 0 000 13.923z" fill="#2F2E41" />
      <path
        d="M277.315 128.828l.915 5.944v1.829h5.258v-2.286s.229-4.115-.686-5.258c-.914-1.143-5.487-.229-5.487-.229zM267.941 128.828l.915 5.944v1.829h5.258v-2.286s.229-4.115-.686-5.258c-.914-1.143-5.487-.229-5.487-.229z"
        fill="#BC8B83"
      />
      <path
        d="M271.479 81.081s-2.022 1.066-.641 3.49c1.382 2.426 9.487 27.512 9.487 27.512l4.675-1.328-4.123-15.865-2.361-9.971-7.037-3.838z"
        fill="#E9E3FD"
      />
      <path d="M279.6 74.416l-.686 10.745-4.801-3.43s1.601-6.4 1.143-6.858l4.344-.457z" fill="#AD7F78" />
      <path d="M277.201 78.874a5.258 5.258 0 100-10.516 5.258 5.258 0 000 10.516z" fill="#BC8B83" />
      <path
        d="M279.601 84.247l-5.172-3.83s-2.372-.971-3.058.172c-.686 1.143-4.115 18.061-2.286 21.262 0 0 9.83 2.057 11.888.914l.914-5.944s1.143-3.658-.686-5.944l-1.6-6.63z"
        fill="#E9E3FD"
      />
      <path
        d="M272.742 80.818s-1.829-1.372-3.43.914c-1.6 2.287-20.804 20.348-20.804 20.348l3.201 3.657 12.574-10.516 8.001-6.401.458-8.002z"
        fill="#E9E3FD"
      />
      <path
        d="M281.087 102.651s-11.317-2.172-12.46-.571l-.229 2.514s-2.515 4.801-1.143 12.575c1.372 7.773.457 12.574.457 12.574s6.173-.686 10.746 0c4.572.686 5.258 0 5.258 0l-2.629-27.092z"
        fill="#98A1AF"
      />
      <path
        d="M283.945 135.687l-6.63.228 2.287 17.147s-1.829 7.316.228 7.773c1.431.289 2.885.442 4.344.457 0 0 2.058 2.058 4.344 1.829a12.796 12.796 0 004.344-1.372s.457-1.371-1.143-1.829c-1.601-.457-5.945-1.829-6.859-6.63l-.915-17.603z"
        fill="#27295D"
      />
      <path
        d="M274.571 135.687l-6.63.228 2.287 17.147s-1.829 7.316.228 7.773c1.431.289 2.885.442 4.344.457 0 0 2.058 2.058 4.344 1.829a12.796 12.796 0 004.344-1.372s.457-1.371-1.143-1.829c-1.601-.457-5.945-1.829-6.859-6.63l-.915-17.603z"
        fill="#27295D"
      />
      <path d="M280.398 67.207a2.88 2.88 0 100-5.761 2.88 2.88 0 000 5.761z" fill="#2F2E41" />
      <path
        d="M284.239 62.767a2.88 2.88 0 00-2.581-2.865 2.88 2.88 0 110 5.73 2.88 2.88 0 002.581-2.865zM277.153 73.058c2.651 0 4.8-1.29 4.8-2.88 0-1.591-2.149-2.88-4.8-2.88-2.652 0-4.801 1.289-4.801 2.88 0 1.59 2.149 2.88 4.801 2.88z"
        fill="#2F2E41"
      />
      <path
        d="M113.334 125.391a3.92 3.92 0 00-.551-5.016c-8.346-8.127-12.712-16.814-12.823-26.055-.179-15.66 11.683-27.726 11.783-27.837a3.947 3.947 0 00-5.589-5.57c-.598.59-14.302 14.572-14.082 33.509.147 11.423 5.281 22.072 15.263 31.624a3.912 3.912 0 002.803 1.099 3.928 3.928 0 002.757-1.211l.439-.543z"
        fill="#27295D"
      />
      <path
        d="M96.763 138.878a5.445 5.445 0 00-.788-6.977c-11.652-11.154-17.614-23.297-17.764-36.118-.255-21.729 16.163-38.438 16.31-38.62a5.464 5.464 0 10-7.733-7.716c-.838.835-19.83 20.214-19.526 46.495.185 15.842 7.289 30.602 21.155 43.873a5.463 5.463 0 007.736-.178c.23-.231.434-.486.61-.759z"
        fill="#27295D"
      />
      <path
        d="M78.698 152.17a7.112 7.112 0 00-1.012-9.108c-15.18-14.531-22.984-30.37-23.155-47.066-.342-28.312 21.078-50.103 21.26-50.336a7.119 7.119 0 00-10.068-10.068c-1.097 1.069-25.848 26.317-25.366 60.64.24 20.651 9.523 39.893 27.577 57.186a7.156 7.156 0 0010.083-.231c.261-.315.49-.656.681-1.017zM307.277 122.391a3.92 3.92 0 01-.637-2.608c.1-.918.521-1.77 1.189-2.408 8.346-8.127 12.711-16.814 12.822-26.055.179-15.66-11.683-27.726-11.783-27.837a3.947 3.947 0 015.589-5.57c.598.59 14.302 14.572 14.082 33.509-.147 11.423-5.281 22.072-15.263 31.624a3.912 3.912 0 01-2.803 1.099 3.916 3.916 0 01-2.756-1.211l-.44-.543z"
        fill="#27295D"
      />
      <path
        d="M323.848 135.878a5.448 5.448 0 01.788-6.977c11.652-11.154 17.614-23.297 17.764-36.118.255-21.729-16.163-38.438-16.31-38.62a5.459 5.459 0 01-1.718-3.899 5.468 5.468 0 015.555-5.543 5.463 5.463 0 013.896 1.726c.838.835 19.831 20.214 19.527 46.495-.185 15.842-7.289 30.602-21.155 43.873a5.475 5.475 0 01-3.905 1.516 5.453 5.453 0 01-3.832-1.694 4.687 4.687 0 01-.61-.759z"
        fill="#27295D"
      />
      <path
        d="M341.913 149.17a7.114 7.114 0 011.012-9.108c15.18-14.531 22.984-30.37 23.156-47.066.341-28.312-21.079-50.103-21.261-50.336a7.123 7.123 0 01-1.543-7.759 7.122 7.122 0 016.578-4.394 7.117 7.117 0 015.034 2.085c1.096 1.069 25.847 26.317 25.365 60.64-.239 20.651-9.523 39.893-27.577 57.186a7.154 7.154 0 01-10.083-.231 6.037 6.037 0 01-.681-1.017z"
        fill="#27295D"
      />
    </svg>
  );
}

export default TrialEndedIllustration;
