import React from 'react';

import Header from './Header';

function PrivateWrapper({ children }) {
  return (
    <React.Fragment>
      <div style={{ flex: '0 1 auto' }}>
        <Header />
      </div>
      {children}
    </React.Fragment>
  );
}

export default PrivateWrapper;
