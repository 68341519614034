import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

function FacebookVideo(props) {
  const [video, setVideo] = useState(<React.Fragment />);

  useEffect(() => {
    setVideo(
      <React.Fragment>
        <div id="fb-root" />
        <div className="fb-video" data-href={props.src} data-width="500" data-show-text="false" />
      </React.Fragment>
    );

    loadScript();

    return () => {
      setVideo(<React.Fragment />);
      const existingScript = document.getElementById('facebookVideo');
      if (existingScript) {
        document.getElementById('facebookVideo').remove();
      }
    };
  }, [props.src]);

  const loadScript = (callback) => {
    const existingScript = document.getElementById('facebookVideo');

    if (existingScript) {
      document.getElementById('facebookVideo').remove();
    }

    const script = document.createElement('script');
    script.src = `https://connect.facebook.net/en_US/sdk.js?uuid=${uuid()}#xfbml=1&version=v3.2`;
    script.id = 'facebookVideo';
    document.body.appendChild(script);
    script.onload = (res) => {
      if (callback) callback(res);
    };
  };

  return video;
}

export default FacebookVideo;
