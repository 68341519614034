import { AspectRatio, Box, Button, Heading, VStack } from '@chakra-ui/react';
import { CompositeDecorator, ContentState, Editor, EditorState, convertFromHTML, convertFromRaw } from 'draft-js';
import React from 'react';

import OnlineGivingIcon from '../../icons/OnlineGiving';

const defaultBodyText = `Type into the BODY TEXT field on the left for your text to show up here. Customize your copy with <b>bold</b>, <i>italicized</i>, or <u>underlined</u> text. <b>Tip: Leaving a field blank in Loop will exclude it from your bulletin.</b>`;
const blocksFromHTML = convertFromHTML(defaultBodyText);
const defaultBodyState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

function OnlineGiving(props) {
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  const Link = (props) => {
    let { url } = props.contentState.getEntity(props.entityKey).getData();
    url = url.replace(/^(?:https?:\/\/)?/i, '');

    return (
      <Box
        as="a"
        href={`https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        color="theme.primary"
        style={{ textDecoration: 'underline' }}
      >
        {props.children}
      </Box>
    );
  };

  const strategyDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  return (
    <VStack spacing={5} align="stretch">
      <Heading color="theme.primary" pl="5" pr="5" align="center" size="md">
        {props.details?.title ? props.details.title : !props.errors.hasOwnProperty('title') && 'Online Giving'}
      </Heading>
      <Box pl="5" pr="5" color="theme.body" opacity={props.details?.body_text ? 1 : '0.4'}>
        {(!props.live || props.details?.body_text) && (
          <Editor
            readOnly={true}
            editorState={
              props.details?.body_text
                ? EditorState.createWithContent(convertFromRaw(props.details.body_text), strategyDecorator)
                : EditorState.createWithContent(defaultBodyState, strategyDecorator)
            }
          />
        )}
      </Box>
      {props.details?.giving_type &&
        (props.details.giving_type === 'website' ? (
          props.details?.url ? (
            <Box align="center">
              <iframe
                title={`${props.details.url} Website`}
                src={`https://${props.details.url}`}
                style={{ width: '100%', height: 'calc(100vh - 6rem)' }}
              />
            </Box>
          ) : (
            !props.live && (
              <AspectRatio ratio={16 / 9}>
                <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
                  <OnlineGivingIcon />
                </Box>
              </AspectRatio>
            )
          )
        ) : (
          <Box align="center">
            <Button
              as={props.details?.button_link && 'a'}
              href={props.details?.button_link && `https://${props.details.button_link}`}
              target={props.details?.button_link && '_blank'}
              isDisabled={props.details?.button_link ? false : true}
              rel="noopener nofollow"
              color="theme.background"
              colorScheme="theme"
              bg="theme.primary"
            >
              {props.details?.button_text ? props.details.button_text : 'Give Now'}
            </Button>
          </Box>
        ))}
    </VStack>
  );
}

export default OnlineGiving;
