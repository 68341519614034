import {
  Box,
  Button,
  ChakraProvider,
  Container,
  Flex,
  Heading,
  VStack,
  extendTheme,
  useDisclosure,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import Announcement from './bulletins/sections/Announcement';
import ConnectCard from './bulletins/sections/ConnectCard';
import Embed from './bulletins/sections/Embed';
import Event from './bulletins/sections/Event';
import Header from './bulletins/sections/Header';
import OnlineGiving from './bulletins/sections/OnlineGiving';
import PrayerRequest from './bulletins/sections/PrayerRequest';
import Service from './bulletins/sections/Service';
import themes from './colorThemes';
import CaretIcon from './icons/Caret';
import LeftArrowIcon from './icons/LeftArrow';
import useAxios from './useAxios';

function Public() {
  const [content, setContent] = useState([]);
  const [header, setHeader] = useState({});
  const [openSection, setOpenSection] = useState({});
  const { isOpen, onToggle } = useDisclosure();
  const [openSectionComponent, setOpenSectionComponent] = useState();
  const [activeTheme, setActiveTheme] = useState('default');
  const { response, isLoading } = useAxios({
    method: 'get',
    endpoint: '/api/v1/public',
  });

  useEffect(() => {
    if (response?.bulletinVersion) {
      const resHeader = response.bulletinVersion.content.filter((section) => section.type === 'header')[0];
      const resContent = response.bulletinVersion.content.filter((section) => section.type !== 'header');

      setHeader(resHeader);
      setContent(resContent);

      if (response.bulletinVersion?.preferences) {
        setActiveTheme(response.bulletinVersion.preferences?.theme);
      }
    }
  }, [response]);

  useEffect(() => {
    window.scroll(0, 0);
    window.location.hash = '';

    if (isOpen) {
      window.location.hash = openSection.id;
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !window.location.hash) {
      return handleClose();
    }

    if (window.location.hash && content.length) {
      const section = content
        .map((sections) => sections.filter((section) => section.id === window.location.hash.replace('#', '')))
        .reduce((acc, curr) => acc.concat(curr), [])[0];
      return handleOpen(section);
    }
  }, [window.location.hash, content]);

  const handleOpen = (section) => {
    let component;

    if (!section) {
      return (window.location.hash = '');
    }

    switch (section.type) {
      case 'connect_card':
        component = <ConnectCard />;
        break;
      case 'embed':
        component = <Embed />;
        break;
      case 'event':
        component = <Event />;
        break;
      case 'online_giving':
        component = <OnlineGiving />;
        break;
      case 'prayer_request':
        component = <PrayerRequest />;
        break;
      case 'service':
        component = <Service />;
        break;
      case 'announcement':
      default:
        component = <Announcement />;
        break;
    }

    setOpenSection(section);
    setOpenSectionComponent(component);
    if (!isOpen) {
      onToggle();
    }

    return;
  };

  const handleClose = () => {
    setOpenSection({});
    setOpenSectionComponent();
    if (isOpen) {
      onToggle();
    }

    return;
  };

  const Card = (props) => {
    return (
      <Box
        bg="theme.card"
        p="7"
        pl="3"
        pr="5"
        align="left"
        style={{ ...props.style, cursor: 'pointer' }}
        onClick={props.onClick}
      >
        <Flex align="center" color="theme.secondary">
          <Box flex="1" pl="5">
            {props.item.details.title}
          </Box>
          <Box fontSize="xl" p="2">
            <CaretIcon />
          </Box>
        </Flex>
      </Box>
    );
  };

  const themeColors = () => {
    const selectedThemeColors = themes.filter((theme) => theme.style === activeTheme)[0].colors;

    return {
      colors: {
        theme: {
          body: selectedThemeColors[0],
          card: selectedThemeColors[4] ? selectedThemeColors[4] : selectedThemeColors[1],
          placeholder: selectedThemeColors[4] ? selectedThemeColors[4] : selectedThemeColors[3],
          primary: selectedThemeColors[1],
          secondary: selectedThemeColors[3],
          background: selectedThemeColors[2],
          // used for checkbox values
          500: selectedThemeColors[1],
          // used for button hover states
          200: selectedThemeColors[1],
        },
      },
      components: {
        Container: {
          variants: {
            bulletin: {
              bg: selectedThemeColors[2],
              color: selectedThemeColors[0],
            },
          },
        },
        Input: {
          defaultProps: {
            variant: 'outline',
          },
          variants: {
            outline: {
              field: {
                bg: 'white',
                color: 'gray.800',
              },
            },
          },
        },
        Select: {
          defaultProps: {
            variant: 'outline',
          },
          variants: {
            outline: {
              field: {
                bg: 'white',
                color: 'gray.800',
              },
            },
          },
        },
        Textarea: {
          defaultProps: {
            variant: 'outline',
          },
          variants: {
            outline: {
              bg: 'white',
              color: 'gray.800',
            },
          },
        },
      },
      shadows: {
        outline: `0 0 0 3px ${selectedThemeColors[1]}`,
      },
      styles: {
        global: {
          body: {
            bg: selectedThemeColors[2],
          },
        },
      },
    };
  };

  const bulletinTheme = extendTheme(themeColors());

  return (
    <ChakraProvider theme={bulletinTheme} colorModeManager={false}>
      {!isLoading &&
        (Object.keys(header).length > 0 ? (
          <Container variant="bulletin" p="0" style={{ position: 'relative', height: 'inherit' }}>
            <AnimatePresence initial={false}>
              {isOpen && openSectionComponent ? (
                <motion.div
                  key="inner"
                  transition={{ type: 'tween' }}
                  initial={{ x: '100%', opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: '100%', opacity: 0 }}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                  }}
                >
                  <Box
                    pt="5"
                    pb={openSection.type === 'embed' || openSection.type === 'online_giving' ? 0 : 10}
                    align="left"
                  >
                    <Button
                      leftIcon={<LeftArrowIcon />}
                      colorScheme="theme"
                      color="theme.body"
                      bg="theme.background"
                      size="sm"
                      variant="ghost"
                      ml="5"
                      onClick={() => handleClose()}
                    >
                      Back
                    </Button>
                    {React.cloneElement(openSectionComponent, {
                      details: openSection.details,
                      live: true,
                      handleClose: onToggle,
                    })}
                  </Box>
                </motion.div>
              ) : (
                <motion.div
                  key="outer"
                  transition={{ type: 'tween' }}
                  initial={{ x: '-100%', opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: '-100%', opacity: 0 }}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                  }}
                >
                  <VStack align="stretch">
                    <Header details={header.details} errors={{}} live={true} />
                    {content.map((sections) =>
                      sections.map((section, index) => {
                        return (
                          <Box key={index}>
                            <Card item={section} onClick={() => handleOpen(section)} />
                          </Box>
                        );
                      })
                    )}
                  </VStack>
                </motion.div>
              )}
            </AnimatePresence>
          </Container>
        ) : (
          <Container pt="10">
            <Heading align="center">There is no active bulletin</Heading>
          </Container>
        ))}
    </ChakraProvider>
  );
}

export default Public;
