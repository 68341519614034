import 'typeface-roboto';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { AuthenticationContextProvider } from './contexts/authentication-context';
import { BulletinContextProvider } from './contexts/bulletin-context';
import ErrorHandler from './ErrorHandler';
import reportWebVitals from './reportWebVitals';

const env = runtimeEnv();

if (env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  const tagManagerArgs = {
    gtmId: env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

const theme = extendTheme({
  fonts: {
    body: 'Roboto',
  },
  fontWeights: {
    hairline: 100,
    thin: 300,
    light: 300,
    normal: 400,
    medium: 400,
    semibold: 500,
    bold: 500,
    extrabold: 500,
    black: 700,
  },
  textStyles: {
    publicHeader: {
      marginTop: 5,
      marginBottom: 10,
      fontWeight: 400,
      textAlign: 'center',
    },
    uppercase: {
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '2px',
      fontSize: '1rem',
    },
    editorLabel: {
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontSize: '0.75rem',
    },
    tipHighlight: {
      letterSpacing: '2px',
      color: '#F08C7D',
      fontWeight: 700,
    },
  },
  colors: {
    brand: {
      50: '#DCDDFC',
      100: '#C6C1F9',
      200: '#B0A4F6',
      300: '#9A88F3',
      400: '#846BF0',
      500: '#6E4FED',
      600: '#5C46C9',
      700: '#4B3CA5',
      800: '#393381',
      900: '#27295D',
    },
    orange: {
      50: '#FBE2DF',
      100: '#F9D1CB',
      200: '#F7C0B8',
      300: '#F4AEA4',
      400: '#F29D91',
      500: '#F08C7D',
      600: '#D57162',
      700: '#B95546',
      800: '#9E3A2B',
      900: '#821e0f',
    },
    // to support the toast notifications
    blue: {
      500: '#6E4FED',
    },
    green: {
      500: '#6E4FED',
    },
  },
  shadows: {
    outline: '0 0 0 3px #C6C1F9',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme} colorModeManager={false}>
      <ErrorHandler>
        <CookiesProvider>
          <AuthenticationContextProvider>
            <BulletinContextProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </BulletinContextProvider>
          </AuthenticationContextProvider>
        </CookiesProvider>
      </ErrorHandler>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
