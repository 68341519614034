import runtimeEnv from '@mars/heroku-js-runtime-env';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { AuthenticationContext } from './contexts/authentication-context';

export default function useAxios({ method, endpoint, data = null, config = null, manual = false }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['access_token']);
  const env = runtimeEnv();
  const auth = useContext(AuthenticationContext);

  const instance = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
    },
  });

  if (cookies?.access_token) {
    instance.defaults.headers.Authorization = `Bearer ${cookies.access_token}`;
  }

  if (auth.state.originalUser?.role >= 400 && Object.keys(auth.state.originalUser).length > 0) {
    instance.defaults.headers.SupportOrganization = auth.state.user.org_id;
  }

  useEffect(() => {
    setIsLoading(true);

    const axiosCall = async () => {
      try {
        instance[method](endpoint, JSON.parse(config), JSON.parse(data))
          .then((res) => {
            setResponse(res.data);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        setError(err);
      }
    };

    if (manual !== false && !manual.length) {
      return;
    }

    return axiosCall();
  }, [method, endpoint, data, config, manual]);

  return { response, error, isLoading };
}
