import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import ChangeCardModal from 'account/ChangeCardModal';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link as ReactLink, useLocation } from 'react-router-dom';

import ActivateSubscriptionModal from './account/ActivateSubscriptionModal';
import { AuthenticationContext } from './contexts/authentication-context';
import BrandWordmarkIcon from './icons/brand/Wordmark';
import HelpIcon from './icons/Help';
import LeftArrowIcon from './icons/LeftArrow';
import PowerIcon from './icons/Power';
import UserIcon from './icons/User';
import UserCircleIcon from './icons/UserCircle';

const Header = (props) => {
  const { state, dispatch } = useContext(AuthenticationContext);
  const location = useLocation();
  const [daysLeft, setDaysLeft] = useState('');
  const [activateSubscriptionModalOpen, setActivateSubscriptionModalOpen] = useState(false);
  const [changeCardModalOpen, setChangeCardModalOpen] = useState(false);
  const env = runtimeEnv();
  const history = useHistory();
  let stripePromise;

  if (env.REACT_APP_STRIPE_LIVE_PUBLIC_API_KEY || env.REACT_APP_STRIPE_TEST_PUBLIC_API_KEY) {
    const stripeMode = state.organization.subscription?.livemode
      ? env.REACT_APP_STRIPE_LIVE_PUBLIC_API_KEY
      : env.REACT_APP_STRIPE_TEST_PUBLIC_API_KEY;
    stripePromise = loadStripe(stripeMode);
  }

  useEffect(() => {
    if (state.organization?.subscription?.status) {
      const trialEnd = dayjs.unix(state.organization.subscription.cancel_at);
      setDaysLeft(Math.round(trialEnd.diff(dayjs(), 'day', true)));
    }
  }, [state.organization?.subscription?.status]);

  const logoutCustomer = () => {
    dispatch({
      type: 'UNMASK_ORGANIZATION',
      payload: '',
    });

    dispatch({
      type: 'UNMASK_USER',
      payload: '',
    });

    history.push('/');
  };

  return (
    <React.Fragment>
      {Object.keys(state.originalUser).length > 0 && (
        <Box bg="brand.100" p="3">
          <Stack direction={['column', 'row']} spacing="5" alignItems="center">
            <Box>
              <Button
                leftIcon={<LeftArrowIcon />}
                size="sm"
                colorScheme="brand"
                variant="ghost"
                onClick={() => logoutCustomer()}
              >
                Return to Organization List
              </Button>
            </Box>
            <Box>
              <Text fontWeight="500">
                You are currently in account: <strong>{state.organization.name}</strong>
              </Text>
            </Box>
          </Stack>
        </Box>
      )}
      {state.organization?.subscription?.status === 'past_due' && (
        <Box bg="orange.500" color="white" p="3">
          <Stack
            direction={['column', 'row']}
            spacing={{ base: '3', md: '10' }}
            style={{ maxWidth: '1000px', margin: '0 auto' }}
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Text fontSize="xl" fontWeight="500">
                There was a problem charging your card.
              </Text>
              <Text fontSize="sm">Please update your card on file to prevent your account from being suspended.</Text>
            </Box>
            <Box>
              <Button onClick={() => setChangeCardModalOpen(true)} color="black">
                Update Card
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
      {location.pathname !== '/create' && state.organization?.subscription?.status === 'trialing' && daysLeft !== null && (
        <Box bg="brand.900" color="white" p="3">
          <Stack
            direction={['column', 'row']}
            spacing={{ base: '3', md: '10' }}
            style={{ maxWidth: '1000px', margin: '0 auto' }}
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Text fontSize="xl" fontWeight="500">{`You have ${daysLeft} day${
                daysLeft !== 1 ? 's' : ''
              } left for your free trial!`}</Text>
              <Text fontSize="sm">
                Loving {env.REACT_APP_APP_NAME}? Activate now! Don’t worry - we won’t charge your card until the free
                trial is complete.
              </Text>
            </Box>
            <Box>
              <Button color="brand.900" onClick={() => setActivateSubscriptionModalOpen(true)}>
                Activate Subscription
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        p={2}
        pl="5"
        pr="5"
        bg="brand.500"
        color="white"
        bgGradient="linear(to-l, brand.500, brand.700)"
        boxShadow="md"
        {...props}
      >
        <Flex align="center" mr={10}>
          <Heading size="xs" as={ReactLink} to="/">
            <BrandWordmarkIcon />
          </Heading>
        </Flex>

        <Box style={{ zIndex: '10' }}>
          <Menu>
            <MenuButton colorScheme="ghost" as={IconButton} icon={<UserCircleIcon width="2em" height="2em" />} />
            <MenuList color="black">
              <MenuItem as={ReactLink} to="/account" icon={<UserIcon width="1.4em" height="1.4em" />}>
                My Account
              </MenuItem>
              <MenuDivider />
              <MenuItem
                as="a"
                target="_blank"
                href={env.REACT_APP_HELP_URL}
                icon={<HelpIcon width="1.4em" height="1.4em" />}
              >
                Help
              </MenuItem>
              <MenuDivider />
              <MenuItem as={ReactLink} to="/logout" icon={<PowerIcon width="1.4em" height="1.4em" />}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <ActivateSubscriptionModal
            open={activateSubscriptionModalOpen}
            handleOpen={setActivateSubscriptionModalOpen}
          />
          <ChangeCardModal open={changeCardModalOpen} handleOpen={setChangeCardModalOpen} />
        </Elements>
      )}
    </React.Fragment>
  );
};

export default Header;
