import { FormControl, HStack, Input, Select, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import countriesStatesJson from '../../countriesStates.json';

function AddressInput(props) {
  const { register, getValues, setValue, watch } = useForm({ mode: 'onChange' });
  const [currentStates, setCurrentStates] = useState([]);
  const watchCountry = watch('country');

  const saveChanges = () => {
    const formValues = getValues();
    props.onChange(formValues);
  };

  useEffect(() => {
    if (countriesStatesJson) {
      setValue('country', 'United States');
    }
  }, [props.open]);

  useEffect(() => {
    if (watchCountry) {
      const states = countriesStatesJson.filter((country) => country.name === watchCountry)[0].states;
      setCurrentStates(states);
    }
  }, [watchCountry]);

  return (
    <VStack spacing="2">
      <FormControl>
        <Input
          type="text"
          ref={register}
          onChange={saveChanges}
          name="street"
          placeholder="Street Address"
          colorScheme={props?.colorScheme && props.colorScheme}
          focusBorderColor={props?.colorScheme && `${props.colorScheme}.primary`}
          autocomplete="shipping street-address address-line1"
        />
      </FormControl>
      <FormControl>
        <Input
          type="text"
          ref={register}
          onChange={saveChanges}
          name="street_second"
          placeholder="Apt, Suite, Bldg"
          colorScheme={props?.colorScheme && props.colorScheme}
          focusBorderColor={props?.colorScheme && `${props.colorScheme}.primary`}
          autocomplete="shipping street-address address-line2"
        />
      </FormControl>
      <HStack>
        <FormControl>
          <Input
            focusBorderColor={props?.colorScheme && `${props.colorScheme}.primary`}
            type="text"
            ref={register}
            onChange={saveChanges}
            name="city"
            placeholder="City"
            colorScheme={props?.colorScheme && props.colorScheme}
            autocomplete="shipping address-level2"
          />
        </FormControl>
        {currentStates.length && (
          <FormControl>
            <Select
              focusBorderColor={props?.colorScheme && `${props.colorScheme}.primary`}
              ref={register}
              onChange={saveChanges}
              name="state"
              placeholder="State"
              colorScheme={props?.colorScheme && props.colorScheme}
              autocomplete="shipping address-level1"
            >
              {currentStates.map((state) => (
                <option value={state.name} key={state.id}>
                  {state.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <Input
            focusBorderColor={props?.colorScheme && `${props.colorScheme}.primary`}
            type="tel"
            ref={register}
            onChange={saveChanges}
            name="zip"
            placeholder="Zip"
            colorScheme={props?.colorScheme && props.colorScheme}
            autocomplete="shipping postal-code"
          />
        </FormControl>
      </HStack>
      <FormControl>
        <Select ref={register} name="country" placeholder="Country" autocomplete="shipping country-name">
          {countriesStatesJson.map((country) => (
            <option value={country.name} key={country.iso3}>
              {country.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </VStack>
  );
}

export default AddressInput;
