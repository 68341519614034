import * as React from 'react';

function EventIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M37.795 4.977h-1.347v2.436a2.955 2.955 0 01-2.951 2.951 2.955 2.955 0 01-2.951-2.951V4.977H11.949v2.436A2.955 2.955 0 019 10.364a2.955 2.955 0 01-2.95-2.951V4.977H4.703A3.848 3.848 0 00.854 8.824v29.759a3.848 3.848 0 003.85 3.847h33.094a3.849 3.849 0 003.849-3.847l-.002-29.76a3.848 3.848 0 00-3.85-3.846zm-.897 31.444c0 .768-.689 1.392-1.54 1.392H7.14c-.852 0-1.54-.622-1.54-1.392V17.145c0-.77.688-1.393 1.54-1.393h28.218c.852 0 1.54.623 1.54 1.393l-.001 19.276z"
        fill="currentColor"
      />
      <path
        d="M9 8.824c.777 0 1.41-.632 1.41-1.411V1.512a1.411 1.411 0 00-2.821 0v5.9c0 .78.632 1.412 1.41 1.412zM33.498 8.824c.78 0 1.412-.632 1.412-1.411V1.512a1.411 1.411 0 00-2.823 0v5.9c0 .78.634 1.412 1.411 1.412zM8.299 18.156h7.466v7.639H8.299v-7.64zM17.696 18.156h7.466v7.639h-7.466v-7.64zM8.299 27.546h7.466v7.639H8.299v-7.64zM17.696 27.546h7.466v7.639h-7.466v-7.64zM27.445 18.156h7.466v7.639h-7.466v-7.64zM27.445 27.546h7.466v7.639h-7.466v-7.64z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EventIcon;
