import { AspectRatio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

function YoutubeVideo(props) {
  const [youtubeLink, setYoutubeLink] = useState('');

  useEffect(() => {
    try {
      let parsedUrl = new URL(props.src);
      let parseUrlParams = new URLSearchParams(parsedUrl.search.slice(1));
      if (!parsedUrl) {
        return;
      }

      if (parsedUrl.pathname.includes('/watch')) {
        const videoId = parseUrlParams.get('v');
        parsedUrl.pathname = `/embed/${videoId}`;
      } else if (parsedUrl.hostname === 'youtu.be') {
        parsedUrl.hostname = `www.youtube.com`;
        parsedUrl.pathname = `/embed${parsedUrl.pathname}`;
      }

      setYoutubeLink(parsedUrl);
    } catch {
      return;
    }
  }, [props.src]);

  return (
    youtubeLink && (
      <AspectRatio ratio={16 / 9}>
        <iframe src={youtubeLink} allowfullscreen="<allowfullscreen>" />
      </AspectRatio>
    )
  );
}

export default YoutubeVideo;
