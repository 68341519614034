import { AspectRatio, Box, Button, Heading, Image, VStack } from '@chakra-ui/react';
import { CompositeDecorator, ContentState, Editor, EditorState, convertFromHTML, convertFromRaw } from 'draft-js';
import React from 'react';

import ImageIcon from '../../icons/Image';

const defaultBodyText = `Type into the BODY TEXT field on the left for your text to show up here. Customize your copy with <b>bold</b>, <i>italicized</i>, or <u>underlined</u> text. <b>Tip: Leaving a field blank in Loop will exclude it from your bulletin.</b>`;
const blocksFromHTML = convertFromHTML(defaultBodyText);
const defaultBodyState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

function Announcement(props) {
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  const Link = (props) => {
    let { url } = props.contentState.getEntity(props.entityKey).getData();
    url = url.replace(/^(?:https?:\/\/)?/i, '');

    return (
      <Box
        as="a"
        href={`https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        color="theme.primary"
        style={{ textDecoration: 'underline' }}
      >
        {props.children}
      </Box>
    );
  };

  const strategyDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  return (
    <VStack spacing={5} align="stretch">
      <Heading color="theme.primary" pl="5" pr="5" align="center" size="md">
        {props.details?.title ? props.details.title : !props.errors.hasOwnProperty('title') && 'Announcement'}
      </Heading>
      {(!props.live || props.details?.image) && (
        <AspectRatio ratio={16 / 9}>
          {props.details?.image ? (
            <Box>
              <Image src={props.details.image} objectFit="cover" width="inherit" />
            </Box>
          ) : (
            <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
              <ImageIcon />
            </Box>
          )}
        </AspectRatio>
      )}
      {(!props.live || props.details?.body_text) && (
        <Box pl="5" pr="5" color="theme.body" opacity={props.details?.body_text ? 1 : '0.4'}>
          <Editor
            readOnly={true}
            editorState={
              props.details?.body_text
                ? EditorState.createWithContent(convertFromRaw(props.details.body_text), strategyDecorator)
                : EditorState.createWithContent(defaultBodyState, strategyDecorator)
            }
          />
        </Box>
      )}
      {(!props.live || props.details?.button_link || props.details?.button_text) && (
        <Box align="center">
          <Button
            color="theme.background"
            bg="theme.primary"
            colorScheme="theme"
            as={props.details?.button_link && 'a'}
            href={props.details?.button_link && `https://${props.details.button_link}`}
            target={props.details?.button_link && '_blank'}
            isDisabled={props.details?.button_link ? false : true}
            rel="noopener nofollow"
          >
            {props.details?.button_text ? props.details.button_text : 'Button Text'}
          </Button>
        </Box>
      )}
    </VStack>
  );
}

export default Announcement;
