import * as React from 'react';

function BrandCurveIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1442 199" {...props}>
      <path
        d="M1318 92.8c-126.9 91.1-292.7 107.8-435.3 43.9-142.9-64.1-300.3-88.9-456-71.6L265.9 82.9c-73.5 8.2-147.8 5.2-220.5-8.8L-2 65v137h1447V1.6l-127 91.2z"
        fill="#fff"
      />
    </svg>
  );
}

export default BrandCurveIcon;
