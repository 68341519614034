import theme from '@chakra-ui/theme';

function themes() {
  return [
    {
      name: 'Default',
      style: 'default',
      colors: [
        theme.colors.gray['600'],
        theme.colors.gray['900'],
        theme.colors.white,
        theme.colors.gray['600'],
        theme.colors.gray['50'],
      ],
    },
    {
      name: 'Blue Sky',
      style: 'blue_sky',
      colors: [theme.colors.blue['900'], theme.colors.blue['600'], theme.colors.blue['50'], theme.colors.white],
    },
    {
      name: 'Dark Mode',
      style: 'dark_mode',
      colors: [
        theme.colors.white,
        theme.colors.cyan['300'],
        theme.colors.gray['800'],
        theme.colors.white,
        theme.colors.gray['600'],
      ],
    },
    {
      name: 'Sea Foam',
      style: 'sea_foam',
      colors: [theme.colors.green['800'], theme.colors.green['600'], theme.colors.green['50'], theme.colors.white],
    },
    {
      name: 'Plumdrop',
      style: 'plumdrop',
      colors: [theme.colors.purple['900'], theme.colors.purple['700'], theme.colors.purple['50'], theme.colors.white],
    },
    {
      name: 'Red Rover',
      style: 'red_rover',
      colors: [theme.colors.red['900'], theme.colors.red['600'], theme.colors.red['50'], theme.colors.white],
    },
  ];
}

export default themes();
