import * as React from 'react';

function HelpIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.864 11.683a.75.75 0 110 1.501.75.75 0 010-1.5z"
        fill="currentColor"
      />
      <path
        d="M8 .08a7.92 7.92 0 110 15.84A7.92 7.92 0 018 .08zm0 .81a7.11 7.11 0 100 14.22A7.11 7.11 0 008 .89z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.16}
      />
      <path
        d="M5.348 6.42a.446.446 0 00.324-.12.446.446 0 00.121-.324c0-1.195.99-2.143 2.143-2.143 1.195 0 2.142.947 2.142 2.143a2.127 2.127 0 01-2.142 2.142.422.422 0 00-.33.149.522.522 0 00-.115.34v2.034c0 .264.21.488.445.488.27 0 .488-.217.488-.488V8.996a3.042 3.042 0 002.587-3.02A3.082 3.082 0 007.936 2.9 3.082 3.082 0 004.86 5.976c0 .136.056.25.149.33a.522.522 0 00.34.115z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default HelpIcon;
