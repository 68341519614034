import * as React from 'react';

function CaretIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.905 1.722l5.559 6.597-5.56 6.597a1.145 1.145 0 000 1.426c.333.395.871.395 1.203 0l6.16-7.31c.16-.188.25-.445.25-.713 0-.267-.09-.525-.25-.714L2.107.295c-.332-.394-.87-.394-1.202 0a1.145 1.145 0 000 1.427z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CaretIcon;
