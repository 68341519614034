import * as React from 'react';

function ImageIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <rect
        x={32}
        y={48}
        width={192}
        height={160}
        rx={8}
        strokeWidth={16}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M32 168l50.343-50.343a8 8 0 0111.314 0l44.686 44.686a8 8 0 0011.314 0l20.686-20.686a8 8 0 0111.314 0L224 184"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <circle fill="currentColor" cx={156} cy={100} r={12} />
    </svg>
  );
}

export default ImageIcon;
