import * as React from 'react';

function AccountSuspendedIllustration(props) {
  return (
    <svg width="10em" height="5em" viewBox="0 0 358 235" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M338 0H20C8.954 0 0 8.954 0 20v195c0 11.046 8.954 20 20 20h318c11.046 0 20-8.954 20-20V20c0-11.046-8.954-20-20-20z"
        fill="#E3F2FA"
      />
      <mask id="Group_855_svg__a" maskUnits="userSpaceOnUse" x={0} y={0} width={358} height={235}>
        <path
          d="M338 0H20C8.954 0 0 8.954 0 20v195c0 11.046 8.954 20 20 20h318c11.046 0 20-8.954 20-20V20c0-11.046-8.954-20-20-20z"
          fill="#E3F2FA"
        />
      </mask>
      <g mask="url(#Group_855_svg__a)">
        <path
          opacity={0.2}
          d="M297.308 92.88c-48.593 0-88.102 39.52-88.102 88.126s39.509 88.125 88.102 88.125 88.101-39.519 88.101-88.125-39.508-88.125-88.101-88.125zm-8.905 121.52c0 2.972-2.413 5.197-5.194 5.197h-18.73c-2.971 0-5.195-2.413-5.195-5.197v-66.789c0-2.971 2.413-5.196 5.195-5.196h18.737c2.971 0 5.195 2.413 5.195 5.196l-.008 66.789zm47.115 0c0 2.972-2.413 5.197-5.195 5.197h-18.737c-2.97 0-5.195-2.413-5.195-5.197v-66.789c0-2.971 2.413-5.196 5.195-5.196h18.737c2.971 0 5.195 2.413 5.195 5.196V214.4z"
          fill="#003462"
        />
        <path
          d="M229.903 25.738H46.428c-12.358 0-22.375 10.02-22.375 22.381v113.024c0 12.361 10.017 22.381 22.375 22.381h183.475c12.357 0 22.375-10.02 22.375-22.381V48.119c0-12.36-10.018-22.38-22.375-22.38z"
          fill="url(#Group_855_svg__paint0_angular)"
        />
        <path d="M252.278 56.512H24.053v30.214h228.225V56.512z" fill="#003462" fillOpacity={0.8} />
        <path
          opacity={0.8}
          d="M223.75 104.631h-29.088a8.392 8.392 0 00-8.391 8.393v28.535a8.392 8.392 0 008.391 8.393h29.088a8.39 8.39 0 008.39-8.393v-28.535a8.39 8.39 0 00-8.39-8.393zM156.904 133.167H50.064a3.076 3.076 0 100 6.154h106.84a3.077 3.077 0 100-6.154zM74.117 144.917H50.064a3.076 3.076 0 100 6.154h24.053a3.077 3.077 0 000-6.154zM156.904 116.94H50.064a3.077 3.077 0 000 6.155h106.84a3.077 3.077 0 100-6.155z"
          fill="#E3F2FA"
        />
        <path
          opacity={0.2}
          d="M305.2 25.738c-13.781 0-24.953 11.173-24.953 24.958 0 13.784 11.172 24.957 24.951 24.957 13.781 0 24.951-11.175 24.951-24.957.002-13.785-11.17-24.958-24.949-24.958zm-.959 37.314c-1.217 1.187-3.362 1.292-4.451.216-.581-.577-.752-1.031-.738-2.054 0-.733.052-1.063.253-1.548.328-.815 1.204-1.683 2.008-1.99.857-.342 2.019-.27 2.716.143 1.606.973 1.724 3.757.212 5.233zm6.553-23.607c-.083.288-.856 2.227-1.713 4.334a1596.56 1596.56 0 00-3.076 7.584c-.846 2.075-1.606 3.83-1.714 3.902-.198.155-1.237.124-1.385-.04-.052-.052.118-1.672.372-3.602.253-1.93.707-5.324.994-7.531.57-4.304.73-5.16 1.057-5.677.276-.443 1.121-.867 1.925-.98.739-.106 2.041-.043 2.644.11.896.226 1.202.899.896 1.9z"
          fill="#003462"
        />
      </g>
      <defs>
        <radialGradient
          id="Group_855_svg__paint0_angular"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-130.05415 215.97656 -312.43789 -188.14006 191.865 4.197)"
        >
          <stop stopColor="#6E4FED" />
          <stop offset={1} stopColor="#4A31B4" stopOpacity={0.4} />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default AccountSuspendedIllustration;
