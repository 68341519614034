import React, { createContext, useReducer } from 'react';

export const BulletinContext = createContext();

const initialState = {
  current: {},
  past: [],
  upcoming: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BULLETINS':
      return {
        current: action.payload.current,
        past: action.payload.past,
        upcoming: action.payload.upcoming,
      };
    default:
      throw new Error();
  }
};

export const BulletinContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <BulletinContext.Provider value={{ state, dispatch }}>{props.children}</BulletinContext.Provider>;
};
