import './App.scss';

import runtimeEnv from '@mars/heroku-js-runtime-env';
import PaymentOverlay from 'account/PaymentOverlay';
import Logout from 'auth/Logout';
import axios from 'axios';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { AuthenticationContext } from './contexts/authentication-context';
import PrivateWrapper from './PrivateWrapper';
import Public from './Public';
import PublicWrapper from './PublicWrapper';

function App() {
  const { state, dispatch } = useContext(AuthenticationContext);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);
  const [domLoading, setDomLoading] = useState(true);
  const env = runtimeEnv();
  const location = useLocation();
  const history = useHistory();

  const instance = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
    },
  });

  const handleLogout = () => {
    const allCookies = Object.keys(cookies);

    allCookies.forEach((cookie) => {
      removeCookie(cookie);
    });

    setDomLoading(false);

    dispatch({
      type: 'SET_USER',
      payload: {},
    });

    dispatch({
      type: 'SET_ORGANIZATION',
      payload: {},
    });

    dispatch({
      type: 'LOGGED_IN',
      payload: false,
    });

    history.push('/');
  };

  useEffect(() => {
    if ((!cookies.access_token && !cookies.refresh_token) || window.location.hostname !== env.REACT_APP_APP_HOSTNAME) {
      return setDomLoading(false);
    }

    const data = {
      grant_type: 'refresh_token',
      refresh_token: cookies.refresh_token,
      client_id: 1,
    };

    instance
      .post(`/api/v1/auth/token`, data)
      .then((res) => {
        const resDetails = res.data;

        dispatch({
          type: 'SET_USER',
          payload: resDetails.user,
        });

        cookies.access_token && removeCookie('access_token');
        cookies.refresh_token && removeCookie('refresh_token');
        setCookie('access_token', resDetails.access_token, { secure: true });
        setCookie('refresh_token', resDetails.refresh_token, { secure: true });

        dispatch({
          type: 'LOGGED_IN',
          payload: true,
        });

        instance
          .get(`/api/v1/organizations/${resDetails.user.org_id}`, {
            headers: { Authorization: `Bearer ${resDetails.access_token}` },
          })
          .then((res) => {
            const orgDetails = res.data.data.attributes;

            dispatch({
              type: 'SET_ORGANIZATION',
              payload: orgDetails,
            });

            setDomLoading(false);
          })
          .catch((err) => handleLogout());
      })
      .catch((err) => handleLogout());
  }, []);

  const AccountDetails = React.lazy(() => import('account/AccountDetails'));
  const BillingHistory = React.lazy(() => import('account/BillingHistory'));
  const BulletinForm = React.lazy(() => import('bulletins/form/BulletinForm'));
  const BulletinList = React.lazy(() => import('bulletins/list/BulletinList'));
  const ForgotPassword = React.lazy(() => import('auth/ForgotPassword'));
  const Login = React.lazy(() => import('auth/Login'));
  const OrganizationList = React.lazy(() => import('internal/OrganizationList'));
  const ResetPassword = React.lazy(() => import('auth/ResetPassword'));
  const Signup = React.lazy(() => import('auth/Signup'));

  const InternalWrapper = () => (
    <PrivateWrapper>
      <OrganizationList />
    </PrivateWrapper>
  );

  const BulletinWrapper = () => (
    <PrivateWrapper>
      <BulletinList />
    </PrivateWrapper>
  );

  const PaymentWrapper = () => (
    <PrivateWrapper>
      <PaymentOverlay />
    </PrivateWrapper>
  );

  const LoginWrapper = () => (
    <PublicWrapper>
      <Login />
    </PublicWrapper>
  );

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          !domLoading &&
          (state.loggedIn ? (
            <PrivateWrapper>
              <Component {...props} />
            </PrivateWrapper>
          ) : (
            <Redirect to="/" />
          ))
        }
      />
    );
  };

  const PublicRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          !state.loggedIn ? (
            <PublicWrapper>
              <Component {...props} />
            </PublicWrapper>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  return (
    <Suspense fallback={<React.Fragment />}>
      <Switch location={location} key={location.key}>
        <Route
          exact
          path="/"
          component={
            !domLoading &&
            (state.loggedIn && state.user.role >= 400 && Object.keys(state.originalUser).length < 1
              ? InternalWrapper
              : ['canceled', 'unpaid', 'incomplete_expired', 'trial_end'].includes(
                  state.organization?.subscription?.status
                ) && state.organization.status !== 2
              ? PaymentWrapper
              : (state.loggedIn && state.user.role < 400) ||
                (state.loggedIn && state.originalUser?.role >= 400 && Object.keys(state.originalUser).length > 0)
              ? BulletinWrapper
              : window.location.hostname === env.REACT_APP_APP_HOSTNAME
              ? LoginWrapper
              : Public)
          }
        />

        <PrivateRoute path="/create" component={BulletinForm} />
        <PrivateRoute path="/account" component={AccountDetails} />
        <PrivateRoute path="/billing" component={BillingHistory} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/responses" component={BulletinList} />
        <PrivateRoute path="/responses/connect" component={BulletinList} />
        <PrivateRoute path="/responses/prayer" component={BulletinList} />

        <PublicRoute path="/forgot" component={ForgotPassword} />
        <PublicRoute path="/reset" component={ResetPassword} />
        <PublicRoute path="/signup" component={Signup} />
      </Switch>
    </Suspense>
  );
}

export default App;
