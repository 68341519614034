import { Box, Container, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

import BrandCurveIcon from './icons/brand/Curve';
import BrandWordmarkIcon from './icons/brand/Wordmark';

function PublicWrapper({ children }) {
  return (
    <Flex justifyContent="center" mb="5">
      <Box bg="brand.500" bgGradient="linear(to-l, brand.500, brand.700)" color="white" width="100%">
        <Container pt="5">
          <Link to="/" style={{ display: 'block', width: 'fit-content' }}>
            <BrandWordmarkIcon />
          </Link>
        </Container>
        <motion.div
          initial={{ opacity: 1, y: '5%' }}
          animate={{ opacity: 1, y: '0' }}
          transition={{ type: 'tween', duration: 1 }}
        >
          <BrandCurveIcon style={{ zIndex: '1' }} />
        </motion.div>
      </Box>
      <Container
        style={{ position: 'absolute', zIndex: '2' }}
        mt={{ base: '8rem', sm: '9rem', md: '6rem' }}
        mb="10"
        bg="white"
        p="5"
        boxShadow={{ md: 'xl' }}
        borderRadius="lg"
      >
        {children}
      </Container>
    </Flex>
  );
}

export default PublicWrapper;
