import { AspectRatio, Box, Button, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { CompositeDecorator, ContentState, Editor, EditorState, convertFromHTML, convertFromRaw } from 'draft-js';
import React from 'react';

import EventIcon from '../../icons/Event';
import ImageIcon from '../../icons/Image';
import LocationIcon from '../../icons/Location';

const defaultBodyText = `Type into the BODY TEXT field on the left for your text to show up here. Customize your copy with <b>bold</b>, <i>italicized</i>, or <u>underlined</u> text. <b>Tip: Leaving a field blank in Loop will exclude it from your bulletin.</b>`;
const blocksFromHTML = convertFromHTML(defaultBodyText);
const defaultBodyState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

function Event(props) {
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  const Link = (props) => {
    let { url } = props.contentState.getEntity(props.entityKey).getData();
    url = url.replace(/^(?:https?:\/\/)?/i, '');

    return (
      <Box
        as="a"
        href={`https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        color="theme.primary"
        style={{ textDecoration: 'underline' }}
      >
        {props.children}
      </Box>
    );
  };

  const strategyDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  return (
    <VStack spacing={5} align="stretch">
      <Heading color="theme.primary" pl="5" pr="5" align="center" size="md">
        {props.details?.title ? props.details.title : !props.errors.hasOwnProperty('title') && 'Event'}
      </Heading>
      {(!props.live || props.details?.image) && (
        <AspectRatio ratio={16 / 9}>
          {props.details?.image ? (
            <Box>
              <Image src={props.details.image} objectFit="cover" width="inherit" />
            </Box>
          ) : (
            <Box align="center" fontSize="6xl" color="theme.primary" bg="theme.placeholder">
              <ImageIcon />
            </Box>
          )}
        </AspectRatio>
      )}
      {(!props.live || props.details?.date || props.details?.start_time || props.details?.end_time) && (
        <Box pl="10">
          <HStack spacing="4" ml="10">
            <Box fontSize="3xl" color="theme.primary">
              <EventIcon />
            </Box>
            <Box flex="1">
              {(!props.live || props.details?.date) && (
                <Text color="theme.body" opacity={props.details?.date ? 1 : '0.4'}>
                  {props.details?.date ? dayjs(props.details.date).format('MMMM D, YYYY') : 'Date'}
                </Text>
              )}
              {(!props.live || props.details?.start_time || props.details?.end_time) && (
                <Text color="theme.body" opacity={props.details?.start_time || props.details?.end_time ? 1 : '0.4'}>
                  {props.details?.start_time
                    ? `${props.details.start_time} ${props.details.start_time_selection}`
                    : !props.live && '12:00 pm'}
                  {props.details?.start_time && props.details?.end_time && ' - '}
                  {props.details?.end_time
                    ? `${props.details.end_time} ${props.details.end_time_selection}`
                    : !props.live && ' - 1:00 pm'}
                </Text>
              )}
            </Box>
          </HStack>
        </Box>
      )}
      {(!props.live || props.details?.location) && (
        <Box pl="10">
          <HStack spacing="4" ml="10">
            <Box fontSize="3xl" color="theme.primary">
              <LocationIcon />
            </Box>
            <Box flex="1">
              <Text style={{ whiteSpace: 'pre-line' }} color="theme.body" opacity={props.details?.location ? 1 : '0.4'}>
                {props.details?.location ? props.details?.location : 'Location'}
              </Text>
            </Box>
          </HStack>
        </Box>
      )}
      {(!props.live || props.details?.body_text) && (
        <Box pl="5" pr="5" color="theme.body" opacity={props.details?.body_text ? 1 : '0.4'}>
          <Editor
            readOnly={true}
            editorState={
              props.details?.body_text
                ? EditorState.createWithContent(convertFromRaw(props.details.body_text), strategyDecorator)
                : EditorState.createWithContent(defaultBodyState, strategyDecorator)
            }
          />
        </Box>
      )}
      {(!props.live || props.details?.button_link || props.details?.button_text) && (
        <Box align="center">
          <Button
            as={props.details?.button_link && 'a'}
            href={props.details?.button_link && `https://${props.details.button_link}`}
            target={props.details?.button_link && '_blank'}
            isDisabled={props.details?.button_link ? false : true}
            rel="noopener nofollow"
            color="theme.background"
            colorScheme="theme"
            bg="theme.primary"
          >
            {props.details?.button_text ? props.details.button_text : 'Button Text'}
          </Button>
        </Box>
      )}
    </VStack>
  );
}

export default Event;
