import React, { createContext, useReducer } from 'react';

export const AuthenticationContext = createContext();

const initialState = {
  loggedIn: false,
  organization: {},
  originalOrganization: {},
  user: {},
  originalUser: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      return {
        loggedIn: state.loggedIn,
        organization: action.payload,
        originalOrganization: state.originalOrganization,
        user: state.user,
        originalUser: state.originalUser,
      };
    case 'MASK_ORGANIZATION':
      return {
        loggedIn: state.loggedIn,
        organization: action.payload,
        originalOrganization: state.organization,
        user: state.user,
        originalUser: state.originalUser,
      };
    case 'UNMASK_ORGANIZATION':
      return {
        loggedIn: state.loggedIn,
        organization: state.originalOrganization,
        originalOrganization: {},
        user: state.user,
        originalUser: state.originalUser,
      };
    case 'MASK_USER':
      return {
        loggedIn: state.loggedIn,
        organization: state.organization,
        originalOrganization: state.originalOrganization,
        user: action.payload,
        originalUser: state.user,
      };
    case 'UNMASK_USER':
      return {
        loggedIn: state.loggedIn,
        organization: state.organization,
        originalOrganization: state.originalOrganization,
        user: state.originalUser,
        originalUser: {},
      };
    case 'SET_USER':
      let userPreferenceObject = action.payload.preferences;
      if (!action.payload.preferences) {
        userPreferenceObject = {};
      }

      return {
        loggedIn: state.loggedIn,
        organization: state.organization,
        originalOrganization: state.originalOrganization,
        user: { ...action.payload, preferences: userPreferenceObject },
        originalUser: state.originalUser,
      };
    case 'UPDATE_USER':
      const userDetails = {
        created_at: state.user.created_at,
        email: action.payload.email,
        first_name: action.payload.first_name,
        id: state.user.id,
        last_name: action.payload.last_name,
        org_id: state.user.org_id,
        preferences: state.user.preferences,
        remember_token: state.user.remember_token,
        role: state.user.role,
        updated_at: state.user.updated_at,
      };

      return {
        loggedIn: state.loggedIn,
        organization: state.organization,
        originalOrganization: state.originalOrganization,
        user: userDetails,
        originalUser: state.originalUser,
      };
    case 'LOGGED_IN':
      return {
        loggedIn: action.payload,
        organization: state.organization,
        originalOrganization: state.originalOrganization,
        user: state.user,
        originalUser: state.originalUser,
      };
    default:
      throw new Error();
  }
};

export const AuthenticationContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AuthenticationContext.Provider value={{ state, dispatch }}>{props.children}</AuthenticationContext.Provider>;
};
