import * as React from 'react';

function WebsiteIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M31.318 24.897h-5.44c1.153-3.884 3.154-6.677 5.44-7.229v7.229zm0 1.664h-5.865a27.141 27.141 0 00-.599 4.99h6.465v-4.99zm0 20.538v-7.228h-5.44c1.153 3.884 3.154 6.674 5.44 7.228zm0-13.884h-6.464c.045 1.678.245 3.35.599 4.992h5.865v-4.992zM17.202 31.55h5.986c.041-1.677.223-3.345.546-4.99h-5.378a14.842 14.842 0 00-1.153 4.99zm1.155 6.654h5.379l-.002.002a29.676 29.676 0 01-.546-4.992h-5.986c.092 1.719.482 3.407 1.153 4.992l.003-.002zm14.625-6.654h6.466a27.51 27.51 0 00-.6-4.99h-5.866v4.99zm0-13.885v7.23h5.44c-1.153-3.884-3.154-6.676-5.44-7.228v-.002zm-5.393.458a15.045 15.045 0 00-8.398 6.773h4.935c.77-2.842 1.975-5.19 3.463-6.773zm0 28.522c-1.488-1.585-2.69-3.931-3.463-6.775h-4.935a15.05 15.05 0 008.398 6.773v.002zm17.52-6.775h-4.933c-.773 2.842-1.975 5.19-3.463 6.775v-.002a15.042 15.042 0 008.395-6.773zm0-14.974a15.03 15.03 0 00-8.396-6.772c1.488 1.584 2.69 3.93 3.463 6.774l4.932-.002zm.836 1.664h-5.379c.325 1.646.507 3.314.546 4.99H47.1a14.895 14.895 0 00-1.155-4.99zM32.982 38.207h5.867c.351-1.641.552-3.313.599-4.992h-6.464l-.002 4.992zM47.1 33.215h-5.988a29.26 29.26 0 01-.546 4.992h5.379a14.895 14.895 0 001.155-4.992zM32.982 47.1c2.285-.554 4.287-3.345 5.44-7.229h-5.44V47.1zM0 37.079V3.88A3.883 3.883 0 013.88 0h39.34a3.885 3.885 0 013.88 3.88v20.78a16.966 16.966 0 00-2.617-3.717V9.187H2.617v27.892c0 .697.566 1.263 1.263 1.263h12.531c.344.908.767 1.782 1.264 2.617H3.88A3.883 3.883 0 010 37.079zM23.55 6.058a.79.79 0 00.79.79H41.6a.79.79 0 00.79-.79v-1.56a.79.79 0 00-.79-.79H24.34a.788.788 0 00-.791.79v1.56zm-7.527-.78a1.57 1.57 0 103.14-.001 1.57 1.57 0 00-3.14.002zm-5.757 0a1.57 1.57 0 103.14-.001 1.57 1.57 0 00-3.14.002zm-5.756 0a1.57 1.57 0 103.14-.001 1.57 1.57 0 00-3.14.002z"
        fill="currentColor"
      />
    </svg>
  );
}

export default WebsiteIcon;
