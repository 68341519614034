import { AspectRatio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

function TwitchVideo(props) {
  const [twitchLink, setTwitchLink] = useState('');

  useEffect(() => {
    try {
      let parsedUrl = new URL(props.src);
      let parseUrlParams = new URLSearchParams(parsedUrl.search.slice(1));
      if (!parsedUrl) {
        return;
      }

      if (parsedUrl.hostname !== 'player.twitch.tv') {
        parsedUrl.hostname = 'player.twitch.tv';
      }

      if (parsedUrl.pathname) {
        if (parsedUrl.pathname.match(/(^[v][0-9])\w+/g)) {
          parseUrlParams.append('video', parsedUrl.pathname.replace('/', ''));
        } else {
          parseUrlParams.append('channel', parsedUrl.pathname.replace('/', ''));
        }

        parsedUrl.pathname = '';
      }

      parseUrlParams.append('parent', window.location.hostname);

      setTwitchLink(`${parsedUrl}?${parseUrlParams}`);
    } catch {
      return;
    }
  }, [props.src]);

  return (
    twitchLink && (
      <AspectRatio ratio={16 / 9}>
        <iframe src={twitchLink} allowfullscreen="<allowfullscreen>" />
      </AspectRatio>
    )
  );
}

export default TwitchVideo;
