import { Box, Button, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import ActivateSubscriptionForm from 'account/ActivateSubscriptionForm';
import ChangeCardForm from 'account/ChangeCardForm';
import axios from 'axios';
import { AuthenticationContext } from 'contexts/authentication-context';
import dayjs from 'dayjs';
import AddIcon from 'icons/Add';
import AccountSuspendedIllustration from 'illustrations/AccountSuspended';
import TrialEndedIllustration from 'illustrations/TrialEnded';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

function BulletinList() {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);
  const env = runtimeEnv();
  const { state, dispatch } = useContext(AuthenticationContext);
  const [trialLength, setTrialLength] = useState('');
  let stripePromise;

  if (env.REACT_APP_STRIPE_LIVE_PUBLIC_API_KEY || env.REACT_APP_STRIPE_TEST_PUBLIC_API_KEY) {
    const stripeMode = state.organization.subscription?.livemode
      ? env.REACT_APP_STRIPE_LIVE_PUBLIC_API_KEY
      : env.REACT_APP_STRIPE_TEST_PUBLIC_API_KEY;
    stripePromise = loadStripe(stripeMode);
  }

  const instance = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'Authorization': `Bearer ${cookies.access_token}`,
    },
  });

  if (state.originalUser?.role >= 400 && Object.keys(state.originalUser).length > 0) {
    instance.defaults.headers.SupportOrganization = state.user.org_id;
  }

  useEffect(() => {
    if (state.organization?.subscription?.status === 'trial_end') {
      const trialStart = dayjs.unix(state.organization.subscription.current_period_start);
      const trialEnd = dayjs.unix(state.organization.subscription.current_period_end);
      setTrialLength(Math.round(trialEnd.diff(trialStart, 'day', true)));
    }
  }, [state.organization?.subscription?.status]);

  const signUpRedirect = () => {
    cookies.access_token && removeCookie('access_token');
    cookies.refresh_token && removeCookie('refresh_token');

    dispatch({
      type: 'SET_USER',
      payload: {},
    });

    dispatch({
      type: 'SET_ORGANIZATION',
      payload: {},
    });

    dispatch({
      type: 'UNMASK_ORGANIZATION',
      payload: '',
    });

    dispatch({
      type: 'UNMASK_USER',
      payload: '',
    });

    dispatch({
      type: 'LOGGED_IN',
      payload: false,
    });

    history.push('/signup');
  };

  return (
    <React.Fragment>
      <Box p="5" style={{ maxWidth: '1000px', margin: '0 auto' }} width="100%">
        {state.organization?.subscription?.status === 'trial_end' && (
          <Stack direction={['column', 'row']} spacing="5rem" align="center" mt="10">
            <VStack spacing="5" align="left">
              <Box fontSize={{ base: '3xl', md: '5xl' }}>
                <TrialEndedIllustration />
              </Box>
              <Heading>Your trial has ended!</Heading>
              <Text>
                We are so glad you were able to test drive {env.REACT_APP_APP_NAME} for {trialLength} day
                {trialLength !== 1 ? 's' : ''}. Your public bulletin link is now inactive.
              </Text>
              <Text>Simply add your payment information to continue keeping your church in the loop!</Text>
            </VStack>
            <Box>
              {stripePromise && (
                <Elements stripe={stripePromise}>
                  <ActivateSubscriptionForm />
                </Elements>
              )}
            </Box>
          </Stack>
        )}
        {state.organization?.subscription?.status === 'unpaid' && (
          <Stack direction={['column', 'row']} spacing="5rem" align="center" mt="10">
            <VStack spacing="5" align="left">
              <Box fontSize={{ base: '3xl', md: '5xl' }}>
                <AccountSuspendedIllustration />
              </Box>
              <Heading>Account Suspended</Heading>
              <Text>Your public bulletin link is inactive because your last payment could not be processed.</Text>
              <Text>Please update your payment information to continue using {env.REACT_APP_APP_NAME}.</Text>
            </VStack>
            <Box>
              {stripePromise && (
                <Elements stripe={stripePromise}>
                  <ChangeCardForm />
                </Elements>
              )}
            </Box>
          </Stack>
        )}
        {state.organization?.subscription?.status === 'canceled' && (
          <Stack direction={['column', 'row']} spacing="5rem" align="center" mt="10">
            <VStack spacing="5" align="left">
              <Heading>Your subscription is canceled.</Heading>
              <Text>Create a new account to continue using {env.REACT_APP_APP_NAME}!</Text>
              <Button leftIcon={<AddIcon />} colorScheme="brand" width="fit-content" onClick={() => signUpRedirect()}>
                Create new account
              </Button>
            </VStack>
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
}

export default BulletinList;
