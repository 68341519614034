import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { AuthenticationContext } from '../contexts/authentication-context';

function Logout() {
  const history = useHistory();
  const { dispatch } = useContext(AuthenticationContext);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);

  useEffect(() => {
    cookies.access_token && removeCookie('access_token');
    cookies.refresh_token && removeCookie('refresh_token');

    dispatch({
      type: 'SET_USER',
      payload: {},
    });

    dispatch({
      type: 'SET_ORGANIZATION',
      payload: {},
    });

    dispatch({
      type: 'UNMASK_ORGANIZATION',
      payload: '',
    });

    dispatch({
      type: 'UNMASK_USER',
      payload: '',
    });

    dispatch({
      type: 'LOGGED_IN',
      payload: false,
    });

    history.push('/');
  }, [cookies.access_token, cookies.refresh_token, dispatch, history, removeCookie]);

  return <React.Fragment />;
}

export default Logout;
