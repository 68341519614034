import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VisuallyHidden,
  useToast,
} from '@chakra-ui/react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';

import { AuthenticationContext } from '../contexts/authentication-context';
import countriesStatesJson from '../countriesStates.json';

function ChangeCardModal(props) {
  const toast = useToast();
  const { register, errors, handleSubmit, setError, clearErrors, setValue, getValues, watch } = useForm();
  const [cookies] = useCookies(['access_token']);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AuthenticationContext);
  const env = runtimeEnv();
  const [currentStates, setCurrentStates] = useState([]);
  const watchCountry = watch('country');

  const instance = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'Authorization': `Bearer ${cookies.access_token}`,
    },
  });

  const stripe = useStripe();
  const elements = useElements();

  const onClose = () => {
    props.handleOpen(false);
  };

  useEffect(() => {
    if (countriesStatesJson) {
      setValue('country', 'United States');
    }
  }, [props.open]);

  useEffect(() => {
    if (watchCountry) {
      const states = countriesStatesJson.filter((country) => country.name === watchCountry)[0].states;
      setCurrentStates(states);
    }
  }, [watchCountry]);

  const onSubmit = (formData) => {
    clearErrors(['card']);

    if (!stripe || !elements) {
      return;
    }

    if (!errors.length) {
      setLoading(true);
    }

    const cardElement = elements.getElement(CardNumberElement);

    const tokenObject = {
      name: formData.name,
      address_line1: formData.street,
      address_state: formData?.state ?? '',
      address_city: formData.city,
      address_zip: formData.zip,
      address_country: formData.country,
    };

    stripe.createToken(cardElement, tokenObject).then((data) => {
      if (data.error) {
        setLoading(false);
        return setError('card', {
          type: 'manual',
          message: data.error.message,
        });
      }

      instance
        .post(`/api/v1/subscriptions/card`, { token: data.token.id })
        .then((res) => {
          instance.get(`/api/v1/organizations/${state.user.org_id}`).then((res) => {
            const orgDetails = res.data.data.attributes;

            dispatch({
              type: 'SET_ORGANIZATION',
              payload: orgDetails,
            });

            toast({
              title: `Your card has been updated successfully!`,
              status: 'success',
              duration: 2000,
              isClosable: true,
            });

            props.handleOpen(false);
            setLoading(false);
          });
        })
        .catch((err) => {
          if (err.response) {
            const errorRes = err.response.data.errors;

            errorRes.forEach((error) => {
              setError('card', {
                type: 'manual',
                message: 'error.detail',
              });
            });
          }

          setLoading(false);
        });
    });
  };

  return (
    <Modal isOpen={props.open} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Card</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Box>
              <FormControl isInvalid={errors.card} mt="3" mb="3">
                <FormLabel>Card Number</FormLabel>
                <Input
                  as={CardNumberElement}
                  options={{
                    showIcon: true,
                    placeholder: '0000 0000 0000 0000',
                    style: {
                      base: {
                        'fontSize': '1.2rem',
                        'lineHeight': '38px',
                        '::placeholder': {
                          color: '#CBD5E0',
                        },
                      },
                    },
                  }}
                />
              </FormControl>
              <HStack spacing="5" mt="3" mb="3">
                <FormControl isInvalid={errors.card}>
                  <FormLabel>Expiration</FormLabel>
                  <Input
                    as={CardExpiryElement}
                    options={{
                      style: {
                        base: {
                          'fontSize': '1.2rem',
                          'lineHeight': '38px',
                          '::placeholder': {
                            color: '#CBD5E0',
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
                <FormControl isInvalid={errors.card}>
                  <FormLabel>Security Code</FormLabel>
                  <Input
                    as={CardCvcElement}
                    options={{
                      style: {
                        base: {
                          'fontSize': '1.2rem',
                          'lineHeight': '38px',
                          '::placeholder': {
                            color: '#CBD5E0',
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </HStack>
              <FormErrorMessage>{errors.card?.message}</FormErrorMessage>
            </Box>
            <FormControl isInvalid={errors.name} mt="3" mb="3">
              <FormLabel>Name on Card</FormLabel>
              <Input
                type="text"
                name="name"
                defaultValue=""
                ref={register({
                  required: `Please enter the cardholder's name.`,
                  validate: (value) => {
                    if (!value.trim()) {
                      return `Please enter the cardholder's name.`;
                    }
                  },
                })}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.street} mt="3" mb="3">
              <FormLabel>Card Billing Address</FormLabel>
              <Input
                type="text"
                name="street"
                placeholder="Street Address"
                defaultValue=""
                ref={register({
                  required: `Please enter the street address.`,
                  validate: (value) => {
                    if (!value.trim()) {
                      return `Please enter the street address.`;
                    }
                  },
                })}
              />
              <FormErrorMessage>{errors.street?.message}</FormErrorMessage>
            </FormControl>
            <HStack spacing="5" mt="3" mb="3">
              <FormControl isInvalid={errors.city}>
                <VisuallyHidden>
                  <FormLabel>City</FormLabel>
                </VisuallyHidden>
                <Input
                  type="text"
                  name="city"
                  placeholder="City"
                  defaultValue=""
                  ref={register({
                    required: `Please enter the city.`,
                    validate: (value) => {
                      if (!value.trim()) {
                        return `Please enter the city.`;
                      }
                    },
                  })}
                />
                <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
              </FormControl>
              {currentStates.length && (
                <FormControl isInvalid={errors.state}>
                  <VisuallyHidden>
                    <FormLabel>State</FormLabel>
                  </VisuallyHidden>
                  <Select
                    bg="white"
                    name="state"
                    placeholder="State"
                    ref={register({
                      required: `Please select the state.`,
                    })}
                  >
                    {currentStates.map((state) => (
                      <option value={state.name} key={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={errors.zip}>
                <VisuallyHidden>
                  <FormLabel>Zip</FormLabel>
                </VisuallyHidden>
                <Input
                  type="tel"
                  name="zip"
                  placeholder="Zip"
                  defaultValue=""
                  ref={register({
                    required: `Please enter the zip code.`,
                    validate: (value) => {
                      if (!value.trim()) {
                        return `Please enter the zip code.`;
                      }
                    },
                  })}
                />
                <FormErrorMessage>{errors.zip?.message}</FormErrorMessage>
              </FormControl>
            </HStack>
            <FormControl>
              <Select ref={register} name="country" placeholder="Country" autocomplete="shipping country-name">
                {countriesStatesJson.map((country) => (
                  <option value={country.name} key={country.iso3}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              isLoading={loading}
              loadingText="Updating Card"
              isDisabled={Object.keys(state.originalUser).length > 0}
              type="submit"
            >
              Update Card
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default ChangeCardModal;
