import * as React from 'react';

function HandleIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.868 13.316a1.816 1.816 0 110-3.632 1.816 1.816 0 010 3.632zM7.868 3.632a1.816 1.816 0 110-3.632 1.816 1.816 0 010 3.632zM7.868 23a1.816 1.816 0 110-3.632 1.816 1.816 0 010 3.632zM1.816 13.316a1.816 1.816 0 110-3.632 1.816 1.816 0 010 3.632zM1.816 3.632a1.816 1.816 0 110-3.632 1.816 1.816 0 010 3.632zM1.816 23a1.816 1.816 0 110-3.632 1.816 1.816 0 010 3.632z"
        fill="currentColor"
      />
    </svg>
  );
}

export default HandleIcon;
