import { AspectRatio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

function VimeoVideo(props) {
  const [vimeoLink, setVimeoLink] = useState('');

  useEffect(() => {
    try {
      let parsedUrl = new URL(props.src);
      let parseUrlParams = new URLSearchParams(parsedUrl.search.slice(1));
      if (!parsedUrl) {
        return;
      }

      if (parsedUrl.hostname !== 'player.vimeo.com') {
        parsedUrl.hostname = 'player.vimeo.com';

        if (parsedUrl.pathname) {
          parsedUrl.pathname = `/video${parsedUrl.pathname}`;
        }
      }

      setVimeoLink(parsedUrl);
    } catch {
      return;
    }
  }, [props.src]);

  return (
    vimeoLink && (
      <AspectRatio ratio={16 / 9}>
        <iframe src={vimeoLink} allowfullscreen="<allowfullscreen>" />
      </AspectRatio>
    )
  );
}

export default VimeoVideo;
